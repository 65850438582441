<template>
  <div class="login" @keydown.enter="handleSubmit">
    <div class="lang">
      <el-select v-model="$i18n.locale" class="el-select-language" @change="saveLang">
        <el-option
          v-for="item in languages"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="login-gif">
    </div>
    <div
      class="login-con">
      <div
        style=" padding-left: 5%;padding-top: 20px;padding-bottom: 10px; color: #4f556e;font-size: 18px;">
        {{$l("login.welcome", "欢迎访问") + $tenant.name}}
      </div>
      <hr align="center" width="90%" color="#e5e5e5" size="1"/>


      <div style="width: 100%;float: left;padding-top: 10px;font-size: 13px">
        <div
          style=" width: 99%; float: left; padding-top: 20px;  height: 170px">
          <el-form ref="loginForm" style="width: 80%;padding-left: 10%;" :model="user" :rules="rules">
            <el-form-item prop="username">
              <el-input v-model="user.username" :placeholder="$t('login.tips.account')">
                <template slot="prepend"><i class="el-icon-user" style="font-size: 16px;"></i></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="user.password" type="password" :placeholder="$t('login.tips.password')" show-password>
                <template slot="prepend"><i class="el-icon-lock" style="font-size: 16px;"></i></template>
              </el-input>
            </el-form-item>

            <el-form-item v-if="showCaptcha" prop="captcha">
              <el-input
                v-model="user.captcha"
                style="width: 185px"
                placeholder="验证码"
                size="medium"
                clearable
                maxlength="4">
                <template slot="prepend">
                  <i class="el-icon-s-promotion" style="font-size: 16px;"></i>
                </template>
              </el-input>
              <img
                :src="captchaImg"
                style="float: right;height: 30px;width:100px;margin-left: 3px"
                @click="getCaptcha()"/>
            </el-form-item>

            <el-button type="primary" style="width: 100%;height: 32px" :loading="loading" @click="handleSubmit">
              {{$t("login.login")}}
            </el-button>
          </el-form>
        </div>
        <div v-if="false" style=" width: 34%; float: left; border-left: 1px solid #e5e5e5; height: 160px;">
          <div style="padding-top: 20px;text-align: center">
            <img :src="$tenant.appDownload" width="110px" style="border-radius: 15px 15px 15px 15px;"/>
          </div>
          <div
            style=" padding-top: 5px; color: #4f556e;text-align: center">
            {{$t("login.qrCodeApp")}}
          </div>
        </div>
      </div>
      <div style="width: 100%;float: left;padding-top: 10px; color: #4f556e;font-size: 13px">
        <div style="width: 65%;float: left;text-align: center">
          {{$t("login.chromeLogin")}}
          <a
            v-if="false"
            style="color: #409eff;font-size: 13px;"
            data-key="button.download"
            href="https://dl.softmgr.qq.com/original/Browser/71.0.3578.98_chrome_installer.exe"
            target="_blank">{{$t("login.chromeDown")}}</a>
        </div>
        <div
          style="color: #409eff;width: 35%;float: left;text-align: center;cursor: pointer"
          @click="dialogVisible=true">
          {{$t("login.forgotPassword")}}
        </div>
      </div>

      <div v-if="false" style="width: 45%;padding-left: 10%;float: left;padding-top: 30px">
        <el-popover placement="top-start" style="color: #409eff;float:left;cursor: pointer" trigger="hover">
          <img :src="$tenant.appDownload" width="150px"/>
          <el-button slot="reference" type="text">{{$t("login.app")}}</el-button>
        </el-popover>
        <div
          style="color: #409eff;font-size: 14px;float:right;padding:10px 5px 10px 0;cursor: pointer"
          @click="dialogVisible=true">
          {{$t("login.forgotPassword")}}
        </div>
      </div>
    </div>
    <div class="copyright" @click="copyrightClick" v-html="copyright">
    </div>
    <el-dialog
      :title="$t('login.forgotPassword')"
      class="small-padding"
      :visible.sync="dialogVisible"
      width="460px"
      :close-on-click-modal="false"
      @close="dialogClose">
      <el-form ref="findPasswordForm" :model="findPassword" :rules="findPasswordRules">
        <el-form-item prop="username">
          <el-input v-model="findPassword.username" :placeholder="$t('common.pleaseEnter')">
            <template slot="prepend"><i class="el-icon-user" style="font-size: 16px;"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="findPassword.phone" :placeholder="$t('common.pleaseEnter')">
            <template slot="prepend"><i class="el-icon-phone" style="font-size: 16px;"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="findPassword.code" :placeholder="$t('common.pleaseEnter')" style="width: 80%">
            <template slot="prepend"><i class="el-icon-message" style="font-size: 16px;"></i></template>
          </el-input>
          <el-button
            style="width: 20%"
            type="primary"
            :disabled="sendMsgBtnDisabled"
            @click="sendMsg">
            {{sendMsgBtnText}}
          </el-button>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input v-model="findPassword.newPassword" :placeholder="$t('common.pleaseEnter')" type="password">
            <template slot="prepend"><i class="el-icon-lock" style="font-size: 16px;"></i></template>
          </el-input>
        </el-form-item>
        <el-button type="primary" style="width: 100%" :loading="loading" @click="nextStep">
          {{$t("login.submit")}}
        </el-button>
      </el-form>
    </el-dialog>
    <change-pwd ref="changePwdDialog"></change-pwd>
  </div>
</template>

<script>
  import auth from "../../util/auth";
  import ChangePwd from "@/views/main/ChangePwd.vue";

  const copyright = window.config.copyright;
  const copyrightLink = window.config.copyrightLink || "http://beian.miit.gov.cn";
  export default {
    components: {ChangePwd},
    data() {
      return {
        dialogVisible: false,
        countdownSeconds: 60,
        sendMsgBtnDisabled: false,
        sendMsgBtnText: this.$t("login.sendMSG"),
        languages: [{label: "中文简体", value: "zh-CN"}, {label: "English", value: "en-US"}],
        showCaptcha: auth.isShowCaptcha(),
        captchaImg: "",
        user: {
          username: "",
          password: "",
          captchaKey: "",
          captcha: "",
        },
        findPassword: {
          username: "",
          phone: "",
          code: "",
          newPassword: "",
        },
        rules: {
          username: {
            required: true,
            message: this.$t("login.account") + " " + this.$t("login.tips.notNull"),
            trigger: "blur",
          },
          password: {
            required: true,
            message: this.$t("login.password") + " " + this.$t("login.tips.notNull"),
            trigger: "blur",
          },
          captcha: {required: true, message: "验证码为4位", trigger: "blur", max: 4, min: 4},
        },
        findPasswordRules: {
          username: {required: true, message: this.$t("login.tips.notNull"), trigger: "blur"},
          phone: {required: true, message: this.$t("login.tips.notNull"), trigger: "blur"},
          code: {required: true, message: this.$t("login.tips.notNull"), trigger: "blur"},
          newPassword: {required: true, min: 6, max: 25, message: this.$t("user.tip.password"), trigger: "blur"},
        },
        loading: false,
        copyright: copyright,
      };
    },
    mounted() {
      if (this.showCaptcha) {
        this.getCaptcha();
      }
    },
    methods: {
      saveLang(lang) {
        window.localStorage.setItem("local", lang);
        location.reload();
      },
      getCaptcha() {
        this.$http.get("captcha/getBase64")
          .then(({data}) => {
            this.user.captchaKey = data.key;
            this.captchaImg = data.img;
          });
      },
      changePwd() {
        this.$refs.changePwdDialog.open(this.user.username);
      },
      handleSubmit() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            let params = {
              username: this.user.username,
              password: this.user.password,
              captchaKey: this.user.captchaKey,
              captcha: this.user.captcha,
            };
            this.$api.getData("account/logins", params).then((res) => {
              res.data.username = this.user.username;
              auth.logout();
              auth.login(res.data);
              this.loading = false;
              this.$router.push({
                name: "home",
                params: {password: this.user.password},
              });
              this.$nextTick(() => {
                auth.hideCaptcha();
                this.showCaptcha = false;
              });
              this.getFavicon();
            }).catch(error => {
              this.loading = false;
              console.log(error.response);
              // if (error.response) {
              //   this.$message.error(this.$l("login.loginFailed", "登录失败") + "，" + error.response.data.message);
              // }
              const status = error.response.status;
              if (status === 428) {
                this.$confirm(error.response.data.message, "提示", {
                  type: "warning",
                  confirmButtonText: "更改密码",
                }).then(() => {
                  this.changePwd();
                });
              } else {
                auth.showCaptcha();
                this.showCaptcha = true;
                this.getCaptcha();
                this.user.captcha = "";
              }
            });
          }
        });
      },
      countdown() {
        if (this.countdownSeconds === 0) {
          this.sendMsgBtnDisabled = false;
          this.sendMsgBtnText = this.$t("login.sendMSG");
          this.countdownSeconds = 60;
        } else {
          this.sendMsgBtnDisabled = true;
          this.sendMsgBtnText = this.$t("login.resend") + this.countdownSeconds + "s";
          this.countdownSeconds--;
          setTimeout(() => {
            this.countdown();
          }, 1000);
        }
      },
      sendMsg() {
        this.findPasswordRules.code.required = false;
        this.findPasswordRules.newPassword.required = false;
        this.$refs.findPasswordForm.validate((valid) => {
          if (valid) {
            let params = {
              username: this.findPassword.username,
              phone: this.findPassword.phone,
            };
            this.$api.getData("account/findPassword/sendMsg", params).then((res) => {
              this.countdown();
              this.$message.success("短信发送成功,10分钟内有效");
            }).catch(error => {
              if (error.response) {
                this.$message.error("短信发送失败," + error.response.data.message);
              }
            });
          }
        });
      },
      nextStep() {
        this.findPasswordRules.code.required = true;
        this.findPasswordRules.newPassword.required = true;
        this.$refs.findPasswordForm.validate((valid) => {
          if (valid) {
            let params = {
              username: this.findPassword.username,
              code: this.findPassword.code,
              newPassword: this.findPassword.newPassword,
            };
            this.$api.getData("account/findPassword/updatePassword", params).then((res) => {
              this.$message.success(this.$t("common.tip.operationSuccess"));
              this.dialogVisible = false;
            }).catch(error => {
              if (error.response) {
                this.$message.error(this.$t("common.tip.operationFailure") + "," + error.response.data.message);
              }
            });
          }
        });
      },
      dialogClose() {
        this.$refs.findPasswordForm.resetFields();
      },
      copyrightClick() {
        window.open(copyrightLink);
      },
      getFavicon() {
        let link = document.querySelector("link[rel~='icon']") || {};
        link.href = this.$tenant.favicon;
      },
    },
  };
</script>

<style lang="scss" scoped>

::v-deep .el-input-group__prepend {
  border-radius: 15px 0px 0px 15px;
}

::v-deep .el-input__inner {
  height: 32px;
  border-radius: 0px 15px 15px 0px;
}

::v-deep .el-button--primary {
  border-radius: 15px 15px 15px 15px;
}

::v-deep .el-select-language .el-input__inner {
  border-radius: 15px 15px 15px 15px !important;
}

.login {
  width: 100%;
  height: 100%;
  background-color: #c0d9f2;
  //background-size: cover;
  position: relative;

  &-con {
    position: absolute;
    right: 170px;
    top: 50%;
    transform: translateY(-60%);
    height: 310px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 15px 15px 15px 15px;
    width: 560px;
  }

  &-gif {
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-60%);
    height: 640px;
    width: 800px;
    background: url(/static/images/bg/landing2-MF.gif) no-repeat;
  }

  .lang {
    width: 100px;
    margin: 10px 10px 0 0;
    float: right;
  }

  .copyright {
    cursor: pointer;
    width: 100%;
    color: white;
    text-align: center;
    position: absolute;
    font-size: 12px;
    bottom: 10px;
  }
}
</style>
